// jQuery
window.$ = window.jQuery = require('jquery');

// Sweetalert
window.Swal = Swal = require('sweetalert2');

// CKEditor
window.ClassicEditor = ClassicEditor = require('@ckeditor/ckeditor5-build-classic');

// Select2
window.select2 = select2 = require('select2/dist/js/select2.min');

// Dropzone
window.Dropzone = Dropzone = require('dropzone/dist/min/dropzone.min');

// Moment.js
global.moment = require('moment/moment');

// Tempus Dominus Bootstrap 4
require('tempusdominus-bootstrap-4/build/js/tempusdominus-bootstrap-4.min');

// Jquery validation
require('jquery-validation');
require('jquery-validation/dist/localization/messages_nl');

// Datatables
require('datatables.net');
require('datatables.net-bs4');

// Bootstrap
require('bootstrap');

// AdminLTE
require('admin-lte');

